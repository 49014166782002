import axios from 'axios';

export const getOnboardingBaseData = async () => {
  const { data } = await axios.get<GETBaseOnboardingData>(
    '/get_available_data_base_set_up/'
  );
  return data;
};

export const fetchAvailableData = async () => {
  const response = await fetch('/get_available_data_base_set_up/');
  const data: { agencies: Agency[] } = await response.json();
  return data.agencies;
};

export const addNewClient = async (agency_id: string, client_name: string) => {
  const { data } = await axios.post('add-new-client/', {
    agency_id,
    client_name,
  });

  return data;
};
