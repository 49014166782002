import { useQuery } from 'react-query';
import { useCmpIntContext } from 'hooks';
import { useState, useRef, useEffect, useMemo } from 'react';

// API REQUESTS
import { fetchCompOverviewData, fetchCompetitorGroups } from 'api';

import {
  OVERVIEW_TABS,
  COMP_INTEL_AD_TYPES,
  OVERVIEW_TOOLTIPS_TEXT,
} from '../../constants/comp-intel';

// COMPONENTS
import MultiDropdown from '../MultiDropdown';
import OutletSpinner from '../OutletSpinner';
import FilterBarWrapper from '../FilterBarWrapper';
import OverviewChart from './OVComponents/OverviewChart';
import DatepickerComponent from '../DatepickerComponent';
import { DateValueType } from 'react-tailwindcss-datepicker';
import OverviewLineChart from './OVComponents/OverviewLineChart';
import TimeFramePicker, { TimeFrameDateRange } from '../TimeFramePicker';

// TYPES
import { OverviewTab, CompetitorGroup, CompIntelAdType } from '../../types';

// UTILS
import {
  filterDailyData,
  downloadChartData,
  filterOverviewData,
  downloadCoverageCompData,
  getTopDailyCompsByCoverage,
} from 'utils/comp-intel-utils/overview-utils';
import { downloadImage } from 'utils/download';

// COMP. INTEL OVERVIEW COMPONENT
function CompIntelOverview() {
  const {
    clientDomain,
    searchOptions,
    selectedSearchOptions,
    onSelectSearchTerms,
    onSelectSearchGroups,
  } = useCmpIntContext();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<'Desktop' | 'Mobile'>('Desktop');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({ startDate: null, endDate: null });

  const [competitorGroupOptions, setCompetitorGroupOptions] = useState<
    CompetitorGroup[]
  >([]);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState<string[]>(
    []
  );
  const [competitorOptions, setCompetitorOptions] = useState<string[]>([]);
  const [preselectedCompetitors, setPreselectedCompetitors] = useState<
    string[]
  >([]);

  const [activeTab, setActiveTab] = useState<OverviewTab>('Coverage');
  const [selectedFilters, setSelectedFilters] = useState<CompIntelAdType[]>([
    'PAID',
  ]);

  // FETCH COMPETITOR OPTIONS (GROUPS AND DOMAINS)
  const { data: compGroupsData } = useQuery(
    ['competitorGroupsData'],
    fetchCompetitorGroups,
    {
      onSuccess: ({ allDomains, competitorGroups }) => {
        setCompetitorOptions([...allDomains]);
        setCompetitorGroupOptions(competitorGroups);

        const initialSelectedGroups = competitorGroups.map(
          (group) => `${group.group_name}`
        );
        setSelectedGroupOptions(initialSelectedGroups);
      },
    }
  );

  // FETCH COMPETITOR CHART (BAR) DATA
  const { data: compOverviewData, isLoading: isLoadingOverviewData } = useQuery(
    [
      'compCompetitorsData',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedSearchOptions.terms,
    ],
    () => fetchCompOverviewData(dateRange, selectedSearchOptions.terms, false),
    { enabled: !!dateRange && !!compGroupsData }
  );

  // FETCH DAILY CHART (LINE) DATA
  const {
    data: compDailyData,
    isLoading: isLoadingDailyData,
    // error: compDataDailyError,
  } = useQuery(
    [
      'compDailyData',
      dateRange?.startDate,
      dateRange?.endDate,
      selectedSearchOptions.terms,
    ],
    () => fetchCompOverviewData(dateRange, selectedSearchOptions.terms, true),
    { enabled: !!dateRange && !!compGroupsData }
  );

  // SET COMPETITOR OPTIONS
  useEffect(() => {
    const allOtherOptions = competitorOptions.filter(
      (domain) => domain !== clientDomain
    );

    if (
      preselectedCompetitors.length === 0 ||
      (clientDomain && !preselectedCompetitors.includes(clientDomain))
    ) {
      setPreselectedCompetitors([clientDomain, ...allOtherOptions]);
    }

    if (
      preselectedCompetitors.includes(clientDomain) &&
      preselectedCompetitors.length === 1
    ) {
      setPreselectedCompetitors([...allOtherOptions]);
    }

    if (clientDomain.length === 0 && compDailyData) {
      const topCompetitors = getTopDailyCompsByCoverage(compDailyData, 10);
      setPreselectedCompetitors(topCompetitors);
    }
  }, [preselectedCompetitors, clientDomain, competitorOptions, compDailyData]);

  // HANDLE COMPETITOR GROUP SELECTIONS
  const handleCompetitorGroupApply = (groups: string[]) => {
    let selectedCompetitors: string[] = [clientDomain];
    let selectedGroups: string[] = [];

    groups.forEach((group_name) => {
      const matchedGroup = competitorGroupOptions.find(
        (g) => g.group_name === group_name
      );
      if (matchedGroup) {
        selectedCompetitors = [
          ...selectedCompetitors,
          ...matchedGroup.domains.split(','),
        ];
        selectedGroups = [...selectedGroups, ...[group_name]];
      }
    });

    setPreselectedCompetitors(selectedCompetitors);
    setSelectedGroupOptions(selectedGroups);
  };

  // SET UNIQUE COMPETITOR (DOMAIN) OPTIONS BASED ON THOSE FOUND IN COMPETITOR GROUPS & DATA
  useEffect(() => {
    setCompetitorOptions(
      Array.from(
        new Set([
          ...(compOverviewData?.unique_brands || []),
          ...(compGroupsData?.allDomains || []),
        ])
      )
    );
  }, [compOverviewData, compGroupsData]);

  // FILTERED DAILY CHART DATA
  const filteredDailyData = useMemo(
    () => filterDailyData(compDailyData, preselectedCompetitors),
    [compDailyData, preselectedCompetitors]
  );

  // FILTERED COMPETITOR CHART DATA
  const filteredOverviewData = useMemo(
    () => filterOverviewData(compOverviewData, preselectedCompetitors),
    [compOverviewData, preselectedCompetitors]
  );

  // HANDLE FILTER TOGGLE (updated to support single selection)
  const toggleFilter = (filter: CompIntelAdType) => {
    setSelectedFilters([filter]); // Replace with the newly selected filter
  };

  // HANDLE TIME FRAME SELECTION
  const onChangeTimeFrame = (timeFrame: TimeFrameDateRange) => {
    setDateRange(timeFrame);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  // DROWDOWN (IS THIS STILL NEEDED?)
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartImage = () => {
    downloadImage('CompetitiveIntelligenceOverview');
  };

  // JSX
  return (
    <div className="py-12">
      <FilterBarWrapper>
        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between w-full items-center">
            <div className="float-start">
              <TimeFramePicker onChange={onChangeTimeFrame} defaultValue="1M" />
            </div>

            <div className="float-end">
              <DatepickerComponent
                value={dateRange}
                onChange={onChangeDatePicker}
                product="comp_int"
              />
            </div>
          </div>
          <div className="flex justify-between md:mt-0 w-full">
            <MultiDropdown
              placeholder={'Search Term Groups'}
              options={searchOptions?.groups || []}
              preselectedOptions={selectedSearchOptions?.groups || []}
              onSelectionChange={onSelectSearchGroups}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
            <MultiDropdown
              placeholder={'Search Terms'}
              options={searchOptions?.terms || []}
              preselectedOptions={selectedSearchOptions?.terms || []}
              onSelectionChange={onSelectSearchTerms}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
            <MultiDropdown
              placeholder={'Competitor Groups'}
              options={competitorGroupOptions.map(
                (group) => `${group.group_name}`
              )}
              preselectedOptions={selectedGroupOptions}
              onSelectionChange={handleCompetitorGroupApply}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
            <MultiDropdown
              placeholder={'Domains'}
              options={competitorOptions}
              preselectedOptions={preselectedCompetitors}
              onSelectionChange={setPreselectedCompetitors}
              dropdownLength="max-h-60"
              dropdownOpenWidth="max-w-60"
            />
          </div>
        </div>
      </FilterBarWrapper>
      <div className="flex justify-end mb-4 mr-4 text-xs text-gray300 font-normal">
        Data updates daily
      </div>

      {/* ---- PAGE CONTENT (CHARTS) ----- */}
      <div
        id="CompetitiveIntelligenceOverview"
        className="mb-4 bg-light border rounded-2xl py-2"
      >
        <div className="flex justify-between items-center mb-2 px-4">
          <div>
            <h2 className="text-2xl font-bold">Overview</h2>
            <p className="text-gray800 text-sm font-bold mt-[2px]">
              How the competition and market is evolving
            </p>
          </div>
          <div
            className="flex items-center"
            ref={dropdownRef}
            id="downloadDropdownMenu-CompetitiveIntelligenceOverview"
          >
            <div className="flex items-center mr-4 text-xs">
              <span
                className={
                  view === 'Desktop'
                    ? 'text-primarygray mr-2'
                    : 'text-gray400 mr-2'
                }
              >
                Desktop
              </span>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={view === 'Mobile'}
                  onChange={() =>
                    setView(view === 'Desktop' ? 'Mobile' : 'Desktop')
                  }
                />
                <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
              </label>
              <span
                className={
                  view === 'Mobile'
                    ? 'text-primarygray ml-2'
                    : 'text-gray400 ml-2'
                }
              >
                Mobile
              </span>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
              alt="dots"
              className="h-6 w-6 ml-2 cursor-pointer"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div
                className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
              >
                <ul>
                  {/* <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={closeDropdown}
                    >
                      View data table
                    </li> */}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      closeDropdown();
                      downloadChartData({
                        activeTab,
                        filters: selectedFilters,
                        overviewData: filteredOverviewData,
                        device: view.toLowerCase() as 'desktop' | 'mobile',
                      });
                      downloadCoverageCompData({
                        activeTab,
                        filters: selectedFilters,
                        dailyData: filteredDailyData,
                        device: view.toLowerCase() as 'desktop' | 'mobile',
                      });
                    }}
                  >
                    Download XLS
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      downloadChartImage();
                      closeDropdown();
                    }}
                  >
                    Download PNG image
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <hr className="border-success mb-6" />
        <div className="flex flex-col lg:flex-row mb-4">
          <div className="flex justify-between items-center p-2 w-full px-4 whitespace-nowrap flex-wrap gap-4">
            <div className="flex space-x-2 bg-fourthgray py-1 px-1 rounded-lg">
              {OVERVIEW_TABS.map((tab) => (
                <div key={tab} className="relative group inline-block">
                  <button
                    className={`${
                      activeTab === tab
                        ? 'px-4 py-[9px] rounded bg-light text-primarygray'
                        : 'text-thirdgray px-4 py-[9px]'
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                  <span
                    className={`absolute z-20 ${
                      tab === 'Rank' ? 'bottom-full mb-3' : 'top-full mt-3'
                    } left-1/2 ${
                      tab === 'Coverage'
                        ? '-translate-x-1/4'
                        : '-translate-x-1/2'
                    } bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap`}
                  >
                    <div className="px-4 py-2">
                      {OVERVIEW_TOOLTIPS_TEXT[tab].map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                      alt="arrow"
                      className={`absolute z-20 ${
                        tab === 'Coverage' ? 'left-1/4' : 'left-1/2'
                      } -translate-x-1/2 ${
                        tab === 'Rank'
                          ? '-bottom-[6px]'
                          : '-top-[6px] rotate-180'
                      }`}
                    />
                  </span>
                </div>
              ))}
            </div>
            <div className="flex space-x-1">
              {COMP_INTEL_AD_TYPES.map((filter) => (
                <span
                  key={filter}
                  className={`text-thirdgray rounded px-3 py-1 cursor-pointer ${
                    selectedFilters.includes(filter)
                      ? 'border border-success rounded'
                      : 'border border-disgreen100 rounded'
                  }`}
                  onClick={() => toggleFilter(filter)} // Call the updated toggleFilter
                >
                  {filter}
                  {selectedFilters.includes(filter) && (
                    <span className="ml-1">✕</span>
                  )}
                </span>
              ))}
            </div>
          </div>
        </div>
        {isLoadingOverviewData ? (
          <div className="py-10 mb-8">
            <OutletSpinner />
          </div>
        ) : filteredOverviewData ? (
          <OverviewChart
            data={filteredOverviewData}
            device={view.toLowerCase()}
            activeTab={activeTab}
            selectedFilters={selectedFilters}
            competitorsToBeIncluded={preselectedCompetitors}
          />
        ) : (
          <div>No data available.</div>
        )}

        {isLoadingDailyData ? (
          <div className="py-10 mb-8">
            <OutletSpinner />
          </div>
        ) : filteredDailyData ? (
          <OverviewLineChart
            data={filteredDailyData}
            device={view.toLowerCase()}
            activeTab={activeTab}
            selectedFilters={selectedFilters}
            competitorsToBeIncluded={preselectedCompetitors}
          />
        ) : (
          <div>No data available.</div>
        )}
      </div>
    </div>
  );
}

export default CompIntelOverview;
