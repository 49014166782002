import {
  MetricData,
  OverviewData,
  OverviewDailyData,
  OverviewDeviceData,
  OverviewDeviceAdData,
} from 'types';

// TOOLTIPS
export const OVERVIEW_TOOLTIPS_TEXT = {
  Coverage: [
    'Percent of time a brand appeared on a search',
    'term/number of times the keyword was',
    'queried during a selected time period',
  ],
  Rank: [
    'Average of all positions for a given',
    'advertiser/total number of positions',
    'possible (or results displayed) when seen',
  ],
  '# Search Terms': [
    'Search terms tracked on the SERP with',
    'chosen engine, location, language, and',
    'study type assignment',
  ],
};

// TAB OPTIONS
export const OVERVIEW_TABS = ['Coverage', 'Rank', '# Search Terms'] as const;

export const DEFAULT_DAILY_DATA: OverviewDailyData = {
  desktop: {
    coverageComp: {},
  },
  mobile: {
    coverageComp: {},
  },
  unique_brands: [],
};

const DEFAULT_METRIC_DATA: MetricData = {
  coverage: {},
  rank: {},
  search_terms: {},
};

export const DEFAULT_DEVICE_AD_DATA: OverviewDeviceAdData = {
  ORGANIC: { ...DEFAULT_METRIC_DATA },
  PAID: { ...DEFAULT_METRIC_DATA },
  SHOPPING: { ...DEFAULT_METRIC_DATA },
};

export const DEFAULT_DEVICE_DATA: OverviewDeviceData = {
  current: {
    ORGANIC: {
      coverage: {},
      rank: {},
      search_terms: {},
    },
    PAID: {
      coverage: {},
      rank: {},
      search_terms: {},
    },
    SHOPPING: {
      coverage: {},
      rank: {},
      search_terms: {},
    },
  },
  previous: {
    ORGANIC: {
      coverage: {},
      rank: {},
      search_terms: {},
    },
    PAID: {
      coverage: {},
      rank: {},
      search_terms: {},
    },
    SHOPPING: {
      coverage: {},
      rank: {},
      search_terms: {},
    },
  },
};

// DEFAULT DATA
export const DEFAULT_OVERVIEW_DATA: OverviewData = {
  desktop: { ...DEFAULT_DEVICE_DATA },
  mobile: { ...DEFAULT_DEVICE_DATA },
  unique_brands: [],
};

export const COMP_INTEL_AD_TYPES = ['PAID', 'ORGANIC', 'SHOPPING'] as const;
