import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PFX_SUPPORT_URL } from '../../constants';
import { useIsFetching, useQuery } from 'react-query';
import { addNewClient, fetchAvailableData } from 'api';
import { ReactNode, useEffect, useState, useRef } from 'react';

// COMPONENTS
import AddClient from './AddClient';
import APIsSetup from './APIsSetup';
import BaseSetup from './BaseSetup';
import Configuration from './Configuration';
import PlatformsSetup from './PlatformsSetup';
import NewBusinessModal from './NewBusinessModal';
import OnboardingSidebar from './OnboardingSidebar';
import ConfirmationPopup from './ConfirmationPopup';
import PleaseWaitPage from './Configuration_comp/PleaseWaitPage';

interface OnboardProps {
  setTopBarButton: (button: ReactNode) => void;
}

interface GoogleSearchAdsAccount {
  customer_id: string[];
  login_customer_id: string;
}

interface MicrosoftAdsAccount {
  customer_id: string;
  account_id: string;
}

interface GoogleAdsAccount {
  customer_id: string[];
  login_customer_id: string;
}

interface GoogleSearchConsoleAccount {
  properties: string[];
}

interface GoogleAnalyticsAccount {
  property_id: string;
}

// interface AdobeAnalyticsAccount {
//   customer_id: string[];
//   login_customer_id: string;
// }

type AccountConfiguration =
  | GoogleSearchAdsAccount
  | MicrosoftAdsAccount
  | GoogleAdsAccount
  | GoogleSearchConsoleAccount
  | GoogleAnalyticsAccount;
// | AdobeAnalyticsAccount;

interface ApiConfiguration {
  [key: string]: AccountConfiguration;
}

interface Api {
  name: string;
  id: string;
  logo: string;
  accounts: AccountConfiguration[];
}

type OnboardingStatus = 'in-progress' | 'pending' | 'success' | 'failure';

const DEFAULT_FORM_STATE = {
  agency: '',
  client: '',
  lineOfBusiness: '',
  market: '',
  region: '',
  new_business: false, // New Business flag
};

function Onboard({ setTopBarButton }: OnboardProps) {
  const [onboardingStatus, setOnboardingStatus] =
    useState<OnboardingStatus>('in-progress');
  const [showPopup, setShowPopup] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const [showNewBusiness, setShowNewBusiness] = useState(true);

  const [formData, setFormData] = useState({ ...DEFAULT_FORM_STATE });

  const [activeModules, setActiveModules] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);

  const [customerId, setCustomerId] = useState<string | null>(null);
  const initialAPIs: Api[] = [
    {
      name: 'Google Search Ads 360',
      id: 'google-search-ads-360',
      logo: `${process.env.PUBLIC_URL}/static/img/search_ads.png`,
      accounts: [{ customer_id: [''], login_customer_id: '' }],
    },
    {
      name: 'Google Ads',
      id: 'google-ads',
      logo: `${process.env.PUBLIC_URL}/static/img/google_ads.png`,
      accounts: [{ customer_id: [''], login_customer_id: '' }],
    },
    {
      name: 'Google Search Console',
      id: 'google-search-console',
      logo: `${process.env.PUBLIC_URL}/static/img/search_console.png`,
      accounts: [{ properties: [''] }],
    },
    {
      name: 'Google Analytics',
      id: 'google-analytics',
      logo: `${process.env.PUBLIC_URL}/static/img/analytics.png`,
      accounts: [{ property_id: '' }],
    },
    {
      name: 'Microsoft Ads',
      id: 'microsoft-ads',
      logo: `${process.env.PUBLIC_URL}/static/img/microsoft_ads.png`,
      accounts: [{ customer_id: '', account_id: '' }],
    },
    // { name: "Adobe Analytics", id: "adobe-analytics", logo: `${process.env.PUBLIC_URL}/static/img/adobe_analytics.png`, accounts: [{ customer_id: [""], login_customer_id: "" }] },
  ];
  const [googleEmail, setGoogleEmail] = useState('');
  const [apis, setApis] = useState<Api[]>(initialAPIs);
  const navigate = useNavigate();
  const apisSetupRef = useRef<{ validateAPIs: () => boolean }>(null);
  const [triggerConsumerUpload, setTriggerConsumerUpload] = useState<
    () => Promise<void>
  >(() => Promise.resolve);
  const [triggerCompetitiveUpload, setTriggerCompetitiveUpload] = useState<
    () => Promise<void>
  >(() => Promise.resolve);
  const [triggerAutoOptimizeUpload, setTriggerAutoOptimizeUpload] = useState<
    () => Promise<void>
  >(() => Promise.resolve);
  const [triggerInsightsUpload, setTriggerInsightsUpload] = useState<
    () => Promise<void>
  >(() => Promise.resolve);
  const [hasUploadedFileConInt, setHasUploadedFileConInt] = useState(false);
  const [hasUploadedFileComInt, setHasUploadedFileComInt] = useState(false);
  const [hasUploadedFileOSAB, setHasUploadedFileOSAB] = useState(false);
  const [hasUploadedFileInsights, setHasUploadedFileInsights] = useState(false);

  const isFetching = useIsFetching() > 0;
  const { data: agencies = [], refetch } = useQuery({
    queryKey: ['base_setup_data'],
    queryFn: fetchAvailableData,
  });

  const handleBackToSettingsClick = () => {
    setShowPopup(true);
  };

  const handleConfirm = () => {
    setShowPopup(false);
    navigate('/settings');
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  useEffect(() => {}, [formData.client]);

  const handleNextStep = async () => {
    if (currentStep === 0) {
      try {
        await addNewClient(formData.agency, formData.client);
        const { data: updatedAgencies = [] } = await refetch();

        const client = updatedAgencies
          .find(({ id }) => id.toString() === formData.agency)
          ?.clients.find(({ name }) => name === formData.client);

        if (!client) {
          alert('Unable to find new client.');
          return;
        }

        setFormData((prev) => ({ ...prev, client: client.id.toString() }));
      } catch (err) {
        let errorMsg = 'Unable to add client.';
        if (axios.isAxiosError(err)) {
          errorMsg = err.response?.data.error;
        }
        alert(errorMsg);
        return;
      }
    }

    if (currentStep === 1) {
      if (
        !formData.agency ||
        !formData.client ||
        !formData.market ||
        !formData.region
      ) {
        alert(
          'Please fill out the agency, client, market and region fields to proceed.'
        );
        return;
      }
    }

    if (currentStep === 2) {
      const isAnyPlatformEnabled = activeModules.some((isEnabled) => isEnabled);
      if (!isAnyPlatformEnabled) {
        alert('Please enable at least one platform to proceed.');
        return;
      }
    }

    // New Business accounts skip steps 2 & 3 in UI.
    // Step #2 is handled setNewBusiness func.
    if (currentStep === 3 || (currentStep === 1 && formData.new_business)) {
      if (!formData.new_business && !apisSetupRef.current?.validateAPIs()) {
        alert('Please correct the errors in API setup to proceed.');
        return;
      }
      await handleSubmit();
    }

    if (currentStep === 4) {
      if (activeModules[2] && !hasUploadedFileConInt) {
        return null;
      }

      if (activeModules[1] && !hasUploadedFileComInt) {
        return null;
      }

      if (activeModules[3] && !hasUploadedFileOSAB) {
        return null;
      }

      if (activeModules[0] && !hasUploadedFileInsights) {
        return null;
      }
    }

    if (currentStep < 4) {
      setCurrentStep((prevStep) => {
        return formData.new_business && prevStep === 1
          ? 4
          : Math.min(prevStep + 1, 4);
      });
    } else {
      try {
        if (activeModules[2] && hasUploadedFileConInt) {
          await triggerConsumerUpload();
        }

        if (activeModules[1] && hasUploadedFileComInt) {
          await triggerCompetitiveUpload();
        }

        if (activeModules[3] && hasUploadedFileOSAB) {
          await triggerAutoOptimizeUpload();
        }

        if (activeModules[0] && hasUploadedFileInsights) {
          await triggerInsightsUpload();
        }

        setOnboardingStatus('pending');

        fetch('start-onboarding/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customer_id: customerId }),
        })
          .then((response) => {
            if (response.ok) {
              response
                .json()
                .then((res) =>
                  navigate('/final', { state: { message: res?.message } })
                );
            } else {
              response.json().then((res) => {
                toast.error(
                  () => (
                    <div>
                      <p className="font-bold">
                        {res.error || 'An unknown error occured.'}
                      </p>
                      <p>
                        Please raise an issue via the{' '}
                        <a
                          className="underline"
                          href={PFX_SUPPORT_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          OneSearch support site
                        </a>{' '}
                        and include a reference to module and client .
                      </p>
                    </div>
                  ),
                  { autoClose: 8000 }
                );
              });
            }
          })
          .catch((error) => {
            console.error('Error in fetch operation:', error);
            setOnboardingStatus('failure');
            alert('An error occurred during the onboarding process.');
          });
      } catch (error) {
        console.error('Error on the onboarding process', error);
        setOnboardingStatus('failure');
        alert('An error occurred during the onboarding process.');
      }
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === 1) {
        setFormData({ ...DEFAULT_FORM_STATE, new_business: true });
      }
      return formData.new_business && prevStep === 4
        ? 1
        : Math.max(prevStep - 1, formData.new_business ? 0 : 1);
    });
  };

  const handleSubmit = async () => {
    const enabledModules = activeModules
      .map((isEnabled, index) => (isEnabled ? index + 1 : null))
      .filter((index) => index !== null);

    const apiConfig: ApiConfiguration = apis.reduce((acc, api) => {
      const validAccount = api.accounts.find((account) => {
        if ('customer_id' in account && 'login_customer_id' in account) {
          return (
            (account as GoogleSearchAdsAccount | GoogleAdsAccount).customer_id
              .length &&
            (account as GoogleSearchAdsAccount | GoogleAdsAccount)
              .login_customer_id
          );
        } else if ('customer_id' in account && 'account_id' in account) {
          return (
            (account as MicrosoftAdsAccount).customer_id &&
            (account as MicrosoftAdsAccount).account_id
          );
        } else if ('properties' in account) {
          return (account as GoogleSearchConsoleAccount).properties.length;
        } else if ('property_id' in account) {
          return (account as GoogleAnalyticsAccount).property_id;
        } else {
          return false;
        }
      });

      if (validAccount) {
        acc[api.id] = validAccount;
      }
      return acc;
    }, {} as ApiConfiguration);

    const payload = {
      agency_id: formData.agency,
      client_id: formData.client,
      line_of_business_id: formData.lineOfBusiness,
      market: formData.market,
      region: formData.region,
      platform: enabledModules,
      account_configuration: apiConfig,
      new_business: formData.new_business,
      google_email_account: googleEmail,
    };

    if (!payload.market || !payload.region || !payload.platform.length) {
      alert('Please fill out all required fields.');
    } else {
      try {
        let response;
        if (customerId) {
          // Update existing customer information
          response = await axios.put('/add-customer-information', {
            ...payload,
            customer_id: customerId,
          });
        } else {
          // Create new customer information
          response = await axios.post('/add-customer-information', payload);
        }
        const data = response.data;

        // Reading the customer_id from the response
        setCustomerId(data.customer_id);
      } catch (err) {
        // Handle error functionality #TODO
      }
    }
  };

  useEffect(() => {
    setTopBarButton(
      <div className="border border-thirdgray rounded-[20px] px-8">
        <button
          className="text-sm font-bold flex items-center text-thirdgray"
          onClick={handleBackToSettingsClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
            alt="arrow"
            className="mr-[6px] rotate-180"
          />
          BACK
        </button>
      </div>
    );
    return () => setTopBarButton(null);
  }, [setTopBarButton]);

  // HANDLE NEW BUSINESS CONFIRMATION
  const setNewBusiness = (isNewBusiness: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      new_business: isNewBusiness,
    }));

    if (isNewBusiness) {
      // Preselect only Competitive Intelligence (step #2)
      setActiveModules([false, true, false, false]);

      // Include set to add client
      setCurrentStep(0);
    }

    setShowNewBusiness(false);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <AddClient
            agencies={agencies}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <BaseSetup
            agencies={agencies}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <PlatformsSetup
            activeModules={activeModules}
            setActiveModules={setActiveModules}
          />
        );
      case 3:
        return (
          <APIsSetup
            ref={apisSetupRef}
            apis={apis}
            setApis={setApis}
            googleEmail={googleEmail}
            setGoogleEmail={setGoogleEmail}
          />
        );
      case 4:
        return (
          <Configuration
            formData={formData}
            customerId={customerId}
            activeModules={activeModules}
            setTriggerConsumerUpload={setTriggerConsumerUpload}
            setTriggerCompetitiveUpload={setTriggerCompetitiveUpload}
            setTriggerAutoOptimizeUpload={setTriggerAutoOptimizeUpload}
            setTriggerInsightsUpload={setTriggerInsightsUpload}
            setHasUploadedFileConInt={setHasUploadedFileConInt}
            setHasUploadedFileComInt={setHasUploadedFileComInt}
            setHasUploadedFileOSAB={setHasUploadedFileOSAB}
            setHasUploadedFileInsights={setHasUploadedFileInsights}
          />
        );
      default:
        return (
          <BaseSetup
            agencies={agencies}
            formData={formData}
            setFormData={setFormData}
          />
        );
    }
  };

  return onboardingStatus === 'pending' ? (
    <PleaseWaitPage
      status={onboardingStatus}
      message="We are creating your account!"
    />
  ) : (
    <div className={`flex ${isFetching ? 'cursor-wait' : ''}`}>
      <NewBusinessModal isOpen={showNewBusiness} onConfirm={setNewBusiness} />
      <div className="flex-1">
        <div className="text-left">
          {renderStepContent()}
          <div className="flex justify-between pt-6 px-2">
            <button
              type="button"
              className={`flex items-center justify-center text-sm font-bold py-2 px-6 w-[150px] h-[34px] bg-gray300 text-light rounded-[20px] disabled:hover:cursor-not-allowed ${
                isFetching ? 'hover:cursor-wait' : ''
              }`}
              onClick={handlePreviousStep}
              disabled={
                (formData.new_business && currentStep === 0) ||
                (formData.new_business && currentStep === 4) ||
                (!formData.new_business && currentStep === 1)
              }
            >
              PREVIOUS
            </button>
            <button
              type="button"
              className={`flex items-center justify-center text-sm font-bold py-2 px-6 w-[150px] h-[34px] bg-success text-light rounded-[20px] ${
                isFetching ? 'hover:cursor-wait' : ''
              }`}
              onClick={handleNextStep}
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
      <OnboardingSidebar
        currentStep={currentStep}
        isNewBusiness={formData.new_business}
      />
      {showPopup && (
        <ConfirmationPopup
          message="Are you sure you want to go back to settings?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default Onboard;
