import { APP_ROUTES } from './constants';
import { useCurrentUserAtom } from 'atoms';
import { client, isLocalEnv, redirectURI } from './api';
import React, { useState, ReactNode, useEffect } from 'react';

import {
  Route,
  Routes,
  Navigate,
  useLocation,
  BrowserRouter as Router,
} from 'react-router-dom';
import Home from './pages/Home';
import TopBar from './components/TopBar';
import Sidebar from './components/Sidebar';
import NoAccessPage from './components/noAccessPage';
import ResourcesPage from './components/ResourcesPage';
import LoadingSpinner from './components/LoadingSpinner';

// CONSUMER INTENT
import ConsumerIntPage from './components/ConsumerIntPage';
import IntentOverview from './components/consumer_int_comp/Overview';
import IntentQueryView from './components/consumer_int_comp/QueryView';
import IntentBrandComp from './components/consumer_int_comp/BrandComparison';
import IntentPFA from './components/consumer_int_comp/ProductFeatureAnalysis';
import IntentRetailerComp from './components/consumer_int_comp/RetailerComparison';

// COMPETITVE INTELLIGENCE
import Comp_IntelligencePage from './components/Comp_IntelligencePage';
import CompIntelReports from './components/competitive_int_comp/Reports';
import CompIntelOverview from './components/competitive_int_comp/Overview';
import CompIntelBrandFocus from './components/competitive_int_comp/BrandFocus';
import CompIntelContentAnalysis from './components/competitive_int_comp/ContentAnalysis';

// AUTO OPTIMIZE
import AutoOptimizePage from './components/AutoOptimizePage';

// ONBOARDING
import SettingsPage from './components/SettingsPage';
import Onboard from './components/Onboarding_comp/Onboard';
import FinalPage from './components/Onboarding_comp/Configuration_comp/FinalPage';
import PleaseWaitPage from './components/Onboarding_comp/Configuration_comp/PleaseWaitPage';

// INSIGHTS
import InsightsPage from './components/InsightsPage';
import InsightsCluster from './components/insights_components/Cluster';
import InsightsOverview from './components/insights_components/Overview';
import InsightsConfiguration from './components/insights_components/Configuration';
import InsightsRecommendations from './components/insights_components/Recommendations';

import {
  User,
  WrapRoute,
  ProtectedRoute,
  NoAccessPageRoute,
} from 'protectedRoute';

interface AppContentProps {
  submitLogout: (e: React.FormEvent) => void;
  loggedInUser: User | null;
}

function AppContent({ submitLogout, loggedInUser }: AppContentProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 1024);
  const [topBarButton, setTopBarButton] = useState<ReactNode>(null);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const isFullPageLayout =
    location.pathname === '/please-wait' || location.pathname === '/final';
  const isOnboardPage = location.pathname === '/onboard';
  return (
    <div className="flex">
      {!isFullPageLayout && !isOnboardPage && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          submitLogout={submitLogout}
          loggedInUser={loggedInUser}
        />
      )}
      <div
        className={`transition-all duration-300 ${
          isFullPageLayout
            ? 'w-full'
            : isOnboardPage
            ? 'mr-[280px] w-[calc(100%-280px)]'
            : isSidebarOpen
            ? 'ml-[280px] w-[calc(100%-280px)]'
            : 'ml-[60px] w-[calc(100%-60px)]'
        }`}
      >
        {!isFullPageLayout && (
          <TopBar isSidebarOpen={isSidebarOpen} button={topBarButton} />
        )}
        <div
          className={`${!isFullPageLayout ? 'p-6 mb-[40px]' : ''} ${
            !isFullPageLayout ? 'mt-[90px]' : ''
          }`}
        >
          <Routes>
            <Route
              path={APP_ROUTES.NO_ACCESS}
              element={
                <NoAccessPageRoute user={loggedInUser}>
                  <NoAccessPage />
                </NoAccessPageRoute>
              }
            />
            <Route element={<WrapRoute user={loggedInUser} />}>
              {/* --- HOME --- */}
              <Route path={APP_ROUTES.HOME} element={<Home />} />

              {/* --- CONSUMER INTENT --- */}
              <Route
                path={APP_ROUTES.CONSUMER_INTENT}
                element={<ConsumerIntPage />}
              >
                <Route
                  index
                  path={APP_ROUTES.CON_INTENT_OVERVIEW}
                  element={<IntentOverview />}
                />
                <Route
                  path={APP_ROUTES.CON_INTENT_PFA}
                  element={<IntentPFA />}
                />
                <Route
                  path={APP_ROUTES.CON_INTENT_RETAILER_COMP}
                  element={<IntentRetailerComp />}
                />
                <Route
                  path={APP_ROUTES.CON_INTENT_BRAND_COMP}
                  element={<IntentBrandComp />}
                />
                <Route
                  path={APP_ROUTES.CON_INTENT_QUERY_VIEW}
                  element={<IntentQueryView />}
                />
              </Route>

              {/* --- COMPETITIVE INTEL. --- */}
              <Route
                path={APP_ROUTES.COMPETITIVE_INTEL}
                element={<Comp_IntelligencePage />}
              >
                <Route
                  index
                  path={APP_ROUTES.COMP_INTEL_OVERVIEW}
                  element={<CompIntelOverview />}
                />
                <Route
                  path={APP_ROUTES.COMP_INTEL_BRAND_FOCUS}
                  element={<CompIntelBrandFocus />}
                />
                <Route
                  path={APP_ROUTES.COMP_INTEL_CONTENT_ANALYSIS}
                  element={<CompIntelContentAnalysis />}
                />
                <Route
                  path={APP_ROUTES.COMP_INTEL_REPORTS}
                  element={<CompIntelReports />}
                />
              </Route>

              {/* --- AUTO OPTIMIZIE --- */}
              <Route
                path={APP_ROUTES.AUTO_OPTIMIZE}
                element={<AutoOptimizePage />}
              />

              {/* --- INSIGHTS --- */}
              <Route
                path={APP_ROUTES.INSIGHTS}
                element={<InsightsPage setTopBarButton={setTopBarButton} />}
              >
                <Route
                  path={APP_ROUTES.INSIGHTS_OVERVIEW}
                  element={<InsightsOverview />}
                />
                <Route
                  path={APP_ROUTES.INSIGHTS_CLUSTER}
                  element={<InsightsCluster />}
                />
                <Route
                  path={APP_ROUTES.INSIGHTS_RECOMMENDATIONS}
                  element={<InsightsRecommendations />}
                ></Route>
                <Route
                  path={APP_ROUTES.INSIGHTS_CONFIGURATION}
                  element={<InsightsConfiguration />}
                />
              </Route>

              {/* --- SETTINGS --- */}
              <Route
                path={APP_ROUTES.SETTINGS}
                element={
                  <ProtectedRoute user={loggedInUser}>
                    <SettingsPage
                      setTopBarButton={setTopBarButton}
                      loggedInUser={loggedInUser}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path={APP_ROUTES.ON_BOARD}
                element={
                  <ProtectedRoute user={loggedInUser}>
                    <Onboard setTopBarButton={setTopBarButton} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={APP_ROUTES.RESOURCES} element={<ResourcesPage />} />
            <Route element={<WrapRoute user={loggedInUser} />}>
              <Route
                path={APP_ROUTES.PLEASE_WAIT}
                element={<PleaseWaitPage />}
              />
              <Route path={APP_ROUTES.FINAL} element={<FinalPage />} />
              <Route path="*" element={<Navigate to={APP_ROUTES.HOME} />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  // Separate loading state for user check
  const [isUserLoading, setIsUserLoading] = useState(true);
  // Separate loading state for login
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(isLocalEnv ? 'client' : 'lion');

  const [currentUser, setCurrentUser] = useState(false);
  const [loggedInUser, setLoggedInUser] = useCurrentUserAtom();

  function set_local_user() {
    client
      .get('/get_user')
      .then((response) => {
        setLoggedInUser(response.data);
        setCurrentUser(true);
      })
      .catch(() => {
        setCurrentUser(false);
      })
      .finally(() => {
        setIsUserLoading(false); // Set loading to false after data is fetched
      });
  }

  useEffect(set_local_user, []);

  function submitLionLogin(e: React.FormEvent) {
    e.preventDefault();

    setIsLoginLoading(true); // Set loading state for login
    client
      .post('/auth/sign_in', {
        email: email,
        password: password,
      })
      .then(function (res) {
        window.location.href = res.data.auth_url;
      })
      .catch(function (error) {
        setIsLoginLoading(false); // Set loading to false if there's an error
        setError('An error occurred during Lion Login.');
        throw error;
      });
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setIsLoginLoading(true); // Ensure loading state is true when code is being processed
      client
        .post(redirectURI, {
          code: code,
        })
        .then((response) => {
          if ('You do not have access!' === response.data) {
            setCurrentUser(false);
          } else {
            set_local_user();
            setCurrentUser(true);
            setError(null);
          }
        })
        .catch((error) => {
          setCurrentUser(false);
          setError('An error occurred during login.');
          throw error;
        })
        .finally(() => {
          setIsLoginLoading(false); // Set loading to false after processing is complete
        });
    }
  }, []);

  function submitClientLogin(e: React.FormEvent) {
    e.preventDefault();

    client
      .post('/client_login', {
        withCredentials: true,
        email: email,
        password: password,
      })
      .then(() => {
        set_local_user();
        setCurrentUser(true);
        setError(null);
      })
      .catch(function (error) {
        setError('An error occured during the client login.');
        throw error;
      });
  }

  function submitLogout(e: React.FormEvent) {
    e.preventDefault();

    client
      .post('/client_logout', {
        withCredentials: true,
      })
      .then(() => {
        setLoggedInUser(null);
        setCurrentUser(false);
        setError(null);
      })
      .catch(function (error) {
        setError('An error occurred during logout.');
        throw error;
      });
  }

  function renderContent() {
    if (isUserLoading || isLoginLoading) {
      return <LoadingSpinner />;
    }

    if (currentUser) {
      if (loggedInUser) {
        return (
          <Router>
            <AppContent
              submitLogout={submitLogout}
              loggedInUser={loggedInUser}
            />
          </Router>
        );
      } else {
        return <LoadingSpinner />;
      }
    } else {
      return (
        <main
          id="login-background"
          className="min-h-screen w-full bg-cover flex flex-col gap-36 items-center justify-center"
        >
          <section className="text-6xl font-semibold">Performics</section>
          <form
            onSubmit={
              activeTab === 'lion' ? submitLionLogin : submitClientLogin
            }
            className={`mb-60 login-shadow bg-white p-8 w-[600px] rounded-2xl ${
              activeTab === 'lion' ? 'space-y-10' : ''
            }`}
          >
            <div className="flex flex-row justify-center items-center gap-28">
              {!isLocalEnv ? (
                <div
                  onClick={() => setActiveTab('lion')}
                  className={
                    activeTab === 'lion' ? 'active-tab' : 'inactive-tab'
                  }
                >
                  LionLogin
                </div>
              ) : (
                <div
                  onClick={() => setActiveTab('client')}
                  className={
                    activeTab === 'client' ? 'active-tab' : 'inactive-tab'
                  }
                >
                  Client Login
                </div>
              )}
            </div>

            {error && (
              <div className="p-2 mt-8 rounded text-gray-700 bg-gray-200">
                <div className="flex justify-between items-center">
                  <div className="font-semibold">{error}</div>
                  <div onClick={() => setError(null)} id="x-icon"></div>
                </div>
                <div>Please try again or contact support.</div>
              </div>
            )}

            {activeTab === 'lion' ? (
              <button
                type="submit"
                className="cursor-pointer select-none text-white bg-success flex w-full items-center justify-center py-2 rounded-3xl font-semibold"
              >
                LOGIN
              </button>
            ) : (
              <div className="space-y-6 mt-8">
                <div className="flex flex-col">
                  <label className="mb-1.5 font-semibold">Your email</label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="rounded border-2 border-gray200 p-1.5 pl-4"
                    id="email"
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <label className="mb-1.5 font-semibold">Password</label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    className="rounded border-2 border-gray200 p-1.5 pl-4"
                    id="password"
                    type="password"
                    placeholder="*******"
                    name="password"
                    required
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-center gap-2">
                    <input
                      className="w-4 h-4"
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                    />
                    <span className="text-gray-900">Remember me</span>
                  </div>
                  <span>
                    <a className="text-success">Forgot password?</a>
                  </span>
                </div>

                <button
                  type="submit"
                  className="cursor-pointer select-none text-white bg-success flex w-full items-center justify-center py-2 rounded-3xl font-semibold"
                >
                  LOGIN
                </button>
              </div>
            )}
          </form>
        </main>
      );
    }
  }
  return renderContent();
}

export default App;
