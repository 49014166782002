import Insights from './Configuration_comp/Insights';
import Consumer_int from './Configuration_comp/Consumer_int';
import AutoOptimize from './Configuration_comp/AutoOptimize';
import Competitive_int from './Configuration_comp/Competitive_int';

interface ConfigurationProps {
  formData: BaseSetupState;
  activeModules: boolean[];
  customerId: string | null;
  setTriggerConsumerUpload: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >;
  setTriggerCompetitiveUpload: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >;
  setTriggerAutoOptimizeUpload: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >;
  setTriggerInsightsUpload: React.Dispatch<
    React.SetStateAction<() => Promise<void>>
  >;
  setHasUploadedFileConInt: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUploadedFileComInt: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUploadedFileOSAB: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUploadedFileInsights: React.Dispatch<React.SetStateAction<boolean>>;
}

function Configuration({
  formData,
  customerId,
  activeModules,
  setHasUploadedFileOSAB,
  setTriggerConsumerUpload,
  setTriggerInsightsUpload,
  setHasUploadedFileConInt,
  setHasUploadedFileComInt,
  setHasUploadedFileInsights,
  setTriggerCompetitiveUpload,
  setTriggerAutoOptimizeUpload,
}: ConfigurationProps) {
  return (
    <>
      <div>
        <h2 className="text-[32px] font-bold">Configuration</h2>
        <h3 className="font-bold text-grayscale70 mt-2">
          General settings for each module’s essential parameters.
        </h3>
        {customerId && <p>Customer ID: {customerId}</p>}
      </div>
      {activeModules[2] && (
        <Consumer_int
          customerId={customerId}
          setTriggerConsumerUpload={setTriggerConsumerUpload}
          setHasUploadedFileConInt={setHasUploadedFileConInt}
        />
      )}
      {activeModules[1] && (
        <Competitive_int
          customerId={customerId}
          isNewBusiness={formData.new_business}
          setTriggerCompetitiveUpload={setTriggerCompetitiveUpload}
          setHasUploadedFileComInt={setHasUploadedFileComInt}
        />
      )}
      {activeModules[3] && (
        <AutoOptimize
          customerId={customerId}
          setTriggerAutoOptimizeUpload={setTriggerAutoOptimizeUpload}
          setHasUploadedFileOSAB={setHasUploadedFileOSAB}
        />
      )}
      {activeModules[0] && (
        <Insights
          customerId={customerId}
          setTriggerInsightsUpload={setTriggerInsightsUpload}
          setHasUploadedFileInsights={setHasUploadedFileInsights}
        />
      )}
    </>
  );
}

export default Configuration;
